/** @jsx jsx **/
import { jsx } from 'theme-ui'
import { FC } from 'react'
import CustomRugStudio from '~/templates/custom-rug-studio'

type Props = {}

const CustomRugStudioPage: FC<Props> = () => {
  return <CustomRugStudio />
}

export default CustomRugStudioPage
